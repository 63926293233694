import Countries from './Countries';
import Languages from './Languages';
import Positions from './Positions';
import CITypes from './CITypes';
import Labels from './Labels';
import Companies from './Companies';
import Workflows from './Workflows';
import CheckListTemplates from './CheckListTemplates';
import DocumentCategories from './DocumentCategories';
import DocumentTypes from './DocumentTypes';

const Catalogs = {
  Countries,
  Languages,
  Positions,
  CITypes,
  Labels,
  Companies,
  Workflows,
  CheckListTemplates,
  DocumentCategories,
  DocumentTypes,
};

export default Catalogs;
