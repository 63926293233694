import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge, Button, ButtonGroup, Card, Col, Container, ListGroup, Modal, Row, Table,
} from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload, faEdit, faTimes, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import usePeopleDocuments from './hook';
import DateShow from '../../../../components/Controls/dates/dateShow';
import FileEditor from './editor';

const MODE = 'table';

function PeopleDocuments({ peopleId }) {
  const { categorizedList, deleteFile, reload } = usePeopleDocuments({ peopleId });
  const [currentCategory, setCurrentCategory] = useState(null);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [fileToEdit, setFileToEdit] = useState(null);
  return (
    <Container fluid>
      <div className="w-100 mt-3">
        <Button variant="outline-primary" className="w-100" size="lg" onClick={() => setFileToEdit('create')}>
          Add document
        </Button>
      </div>
      <Row className="my-3">
        <Col xxl={2} xl={3} lg={4} md={4} sm={6} className="mx-2 py-4">
          <ListGroup>
            {categorizedList.map((cat) => (
              <ListGroup.Item
                key={cat.id}
                variant="primary"
                action
                active={currentCategory === cat.id}
                onClick={() => setCurrentCategory(cat.id)}
              >
                <div className="d-flex">
                  <h5 className="flex-fill">{cat.repr}</h5>
                  <span className="d-inline-flex gap-1 align-items-start">
                    {!!cat.expireds && <Badge bg="danger">{cat.expireds}</Badge>}
                    {!!cat.dangers && <Badge bg="warning">{cat.dangers}</Badge>}
                    {!!cat.warnings && <Badge bg="info">{cat.warnings}</Badge>}
                  </span>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col className="border rounded">
          {MODE === 'card' && (
            <Row xxl={4} className="g-3">
              {categorizedList.filter((cat) => (cat.id === currentCategory)).reduce((R, cat) => [
                ...R,
                cat.files.map((f) => (
                  <Col key={f.id}>
                    <Card variant="danger">
                      <Card.Header>
                        <Card.Title className="d-flex">
                          <div className="flex-fill">
                            {f.document_type.repr}
                            <Button variant="link" onClick={() => setFileToEdit(f.id)}>
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </div>
                          <div>
                            {f.expired && (<Badge bg="danger">Expired</Badge>)}
                            {f.danger && (<Badge bg="warning">Soon expired</Badge>)}
                            {f.warning && (<Badge bg="info">Warning</Badge>)}
                          </div>
                        </Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <p>
                          Issued
                          <DateShow value={f.issue_date} className="mx-2 fw-bold" />
                          by
                          {' '}
                          <span className="fw-bold">{f.issuer}</span>
                        </p>
                        <p>
                          <span className={classNames('px-2 pb-2 pt-1 rounded', {
                            'bg-danger': f.expired,
                            'bg-warning': f.danger,
                            'bg-info': f.warning,
                            'text-white': f.warning || f.danger || f.expired,
                          })}
                          >
                            Valid to
                            <DateShow value={f.valid_to} className="mx-2" />
                          </span>
                        </p>
                        <p className="small text-muted">
                          Uploaded
                          <DateShow value={f.date_add} className="mx-2 fw-bold" />
                          by
                          {' '}
                          <span className="fw-bold">{f.user_add.repr}</span>
                        </p>
                      </Card.Body>
                      <Card.Footer className="d-flex gap-2 justify-content-end">
                        <Button variant="primary" href={f.file} target="_blank">
                          <FontAwesomeIcon icon={faDownload} className="me-2" />
                          Download
                        </Button>
                        <Button variant="danger" onClick={() => setFileToDelete(f.id)}>
                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                          Delete
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Col>
                )),
              ], [])}
            </Row>
          )}
          {MODE === 'table' && (
            <Table striped responsive hover>
              <thead>
                <tr>
                  <th>Document type</th>
                  <th>Issue date</th>
                  <th>Expired date</th>
                  <th>Uploaded</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {categorizedList.filter((cat) => (cat.id === currentCategory)).reduce((R, cat) => [
                  ...R,
                  cat.files.map((f) => (
                    <tr key={f.id}>
                      <td>
                        <Button variant="link" href={f.file} target="_blank" className="d-inline-flex">
                          <span className="my-1 me-2">
                            {f.document_type.repr}
                          </span>
                          <span>
                            {f.expired && (<Badge bg="danger">Expired</Badge>)}
                            {f.danger && (<Badge bg="warning">Soon expired</Badge>)}
                            {f.warning && (<Badge bg="info">Warning</Badge>)}
                          </span>
                        </Button>
                      </td>
                      <td className="align-middle">
                        <DateShow value={f.issue_date} className="mx-2 fw-bold" />
                        {!!f.issuer && (
                          <>
                            by
                            {' '}
                            <span className="fw-bold">{f.issuer}</span>
                          </>
                        )}
                      </td>
                      <td className="align-middle">
                        {f.valid_to && (
                          <DateShow value={f.valid_to} className="mx-2" />
                        )}
                      </td>
                      <td className="align-middle">
                        <DateShow value={f.date_add} className="me-2 fw-bold" />
                        by
                        {' '}
                        <span className="fw-bold">{f.user_add.repr}</span>
                      </td>
                      <td className="align-middle">
                        <div className="d-inline-flex w-100">
                          <ButtonGroup className="ms-auto">
                            <Button variant="outline-primary" onClick={() => setFileToEdit(f.id)}>
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <Button variant="outline-danger" onClick={() => setFileToDelete(f.id)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </ButtonGroup>
                        </div>

                      </td>
                    </tr>
                  ))], [])}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      <Modal show={!!fileToDelete} onHide={() => setFileToDelete(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete file</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure?
        </Modal.Body>
        <Modal.Footer className="d-flex gap-2 justify-content-end">
          <Button
            variant="danger"
            onClick={() => {
              deleteFile(fileToDelete);
              setFileToDelete(null);
            }}
          >
            <FontAwesomeIcon icon={faTrash} className="me-2" />
            Delete
          </Button>
          <Button
            variant="secondary"
            onClick={() => setFileToDelete(null)}
          >
            <FontAwesomeIcon icon={faTimes} className="me-2" />
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={!!fileToEdit} onHide={() => setFileToEdit(null)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {fileToEdit === 'create' ? 'Upload new file' : 'Edit file'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!fileToEdit && (
            <FileEditor
              id={fileToEdit}
              onSave={() => {
                setFileToEdit(null);
                reload();
              }}
              peopleId={peopleId}
            />
          )}
        </Modal.Body>
      </Modal>
    </Container>
  );
}

PeopleDocuments.propTypes = {
  peopleId: PropTypes.number.isRequired,
};

export default PeopleDocuments;
