import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import DateShow from '../../../components/Controls/dates/dateShow';

function DocumentReportRow({ row }) {
  return (
    <tr>
      <td className="align-middle">
        <OverlayTrigger
          placement="auto"
      // delay={{ show: 250, hide: 400 }}
          overlay={(
            <Tooltip>
              <img src={row.people.photo} alt={`${row.people.name} ${row.people.family_name}`} className="rounded" style={{ maxHeight: '50vh' }} />
            </Tooltip>
          )}
        >
          <Link to={`/candidates/${row.people.id}/`}>
            {`${row.people.name} ${row.people.family_name}`}
          </Link>
        </OverlayTrigger>
      </td>
      <td className="align-middle">
        <DateShow value={row.issue_date} />
      </td>
      <td className="align-middle">
        {row.issuer}
      </td>
      <td className={classNames('align-middle', {
        'text-decoration-line-through': row.is_expired,
        'bg-danger text-white': row.is_danger,
        'bg-warning': row.is_warning,
      })}
      >
        <DateShow value={row.valid_to} />
      </td>
      <td className="align-middle">
        <Button href={row.file} download target="_blank">
          <FontAwesomeIcon icon={faLink} />
        </Button>
      </td>
    </tr>
  );
}

DocumentReportRow.propTypes = {
  row: PropTypes.shape({
    people: PropTypes.shape({
      photo: PropTypes.string,
      name: PropTypes.string,
      family_name: PropTypes.string,
    }),
    issue_date: PropTypes.string,
    issuer: PropTypes.string,
    valid_to: PropTypes.string,
    is_danger: PropTypes.bool,
    is_expired: PropTypes.bool,
    is_warning: PropTypes.bool,
  }).isRequired,
};

export default DocumentReportRow;
