import React, {
  useState, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Nav, Modal, Button, Badge, Container, Row, Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faFileLines, faTimes, faUpDownLeftRight,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { EditorControls } from '../../../BasicEditor/editorControls';
import { useDict } from '../../../BaseLister/hook';

const notNullableAttrs = [
  'name', 'document_type', 'issue_date', 'issuer', 'valid_to',
];
const savingAttrs = [
  ...notNullableAttrs, 'comment', 'folder',
];

function getFileIcon(isDragging, DragOver, dragAllowed) {
  if (!isDragging) return faFileLines;
  if (DragOver === 'Folders' && dragAllowed) return faUpDownLeftRight;
  return faTimes;
}

function Files({
  files, folders, onSelect, selected, onEditFile, onDeleteFile, dragAllowed,
}) {
  const [editedFile, setEditedFile] = useState(null);
  const [deleteModalOpened, setDeleteModalOpened] = useState(null);
  const currentFile = useMemo(
    () => files.filter((f) => f.id === selected).reduce((R, f) => f, {}),
    [files, selected],
  );

  const folderValues = useMemo(
    () => folders.map((f) => ({ value: f.id, display_name: f.name })),
    [folders],
  );

  const onFileSave = useCallback(
    (e) => {
      const data = savingAttrs.reduce((R, attr) => ({ ...R, [attr]: editedFile[attr] }), { id: selected });
      onEditFile(e, data);
      setEditedFile(null);
    },
    [editedFile, onEditFile, selected],
  );

  const dctDict = useDict({ url: '/api/cat/document_type/' });
  const dctvalues = useMemo(
    () => {
      const cats = dctDict.data.reduce((R, v) => ({ ...R, [v.category.id]: v.category }), {});
      return Object.values(cats)
        .sort((a, b) => {
          if (a.repr > b.repr) return 1;
          if (a.repr < b.repr) return -1;
          return 1;
        })
        .reduce((R, cat) => [
          ...R,
          { value: -cat.id, display_name: cat.repr, disabled: true },
          ...dctDict.data.filter((dc) => dc.category.id === cat.id).map((dc) => ({
            value: dc.id,
            display_name: dc.repr,
          })),
        ], []);
    },
    [dctDict.data],
  );

  const canBeSaved = useMemo(
    () => {
      if (!editedFile) return true;
      return notNullableAttrs.reduce((R, attr) => R && !!editedFile[attr], true);
    },
    [editedFile],
  );

  return (
    <div className="d-flex flex-column h-100">
      <Nav className="gap-2">
        <Button
          size="sm"
          variant="outline-primary"
          disabled={!selected}
          onClick={() => {
            setEditedFile(currentFile);
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button size="sm" variant="outline-danger" onClick={() => setDeleteModalOpened(true)} disabled={!selected}>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </Nav>
      <Modal show={deleteModalOpened} onHide={() => setDeleteModalOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Deleting &quot;
            {currentFile.name}
            &quot;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Are you sure to delete file ${currentFile.name}?`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={(e) => {
              onDeleteFile(e, selected);
              setDeleteModalOpened(false);
            }}
          >
            Yes, sure
          </Button>
          <Button variant="secondary" onClick={() => setDeleteModalOpened(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Droppable droppableId="Files">
        {(provided) => (
          <div
            className="flex-fill py-1 px-2 border bg-white h-100 overflow-auto"
            {...provided.droppabeProps}
            ref={provided.innerRef}
          >
            {files.map((f, index) => (
              <Draggable key={f.id} draggableId={`file-${f.id}`} index={index}>
                {(dragProvided, snapshot) => (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                  <div
                    role="listitem"
                    key={f.id}
                    className={classNames('px-2 py-1 rounded ', `ps-${f.level}`, { 'bg-secondary-subtle': f.id === selected })}
                    onClick={(e) => onSelect(e, f.id)}
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                  >
                    <FontAwesomeIcon
                      icon={getFileIcon(snapshot.isDragging, snapshot.draggingOver, dragAllowed)}
                      className="me-2"
                    />
                    {f.name}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {editedFile && (
        <Modal show={!!editedFile} onHide={() => setEditedFile(null)}>
          <Modal.Header closeButton>
            <Modal.Title>
              Edit file
              <Badge bg="primary" className="mx-2">{editedFile.name}</Badge>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row>
                <Col md={8}>
                  <EditorControls.StringInput
                    label="Name"
                    value={editedFile.name}
                    onChange={(e, v) => setEditedFile((o) => ({ ...o, name: v }))}
                    required
                  />
                </Col>
                <Col md={4}>
                  <EditorControls.SelectorInput
                    label="Folder"
                    value={editedFile.folder}
                    onChange={(e, v) => setEditedFile((o) => ({ ...o, folder: v }))}
                    values={folderValues}
                    required={false}
                    nullLabel="root"
                  />
                </Col>
                <Col md={12}>
                  <EditorControls.TextInput
                    label="Comment"
                    value={editedFile.comment}
                    onChange={(e, v) => setEditedFile((o) => ({ ...o, comment: v }))}
                    rows={4}
                  />
                </Col>
                <Col md={4}>
                  <EditorControls.SelectorInput
                    label="File type"
                    value={editedFile.document_type}
                    onChange={(e, v) => setEditedFile((o) => ({ ...o, document_type: v }))}
                    values={dctvalues}
                    required
                    searchable={false}
                  />
                </Col>
                <Col md={4}>
                  <EditorControls.DateInput
                    label="Date of Issue"
                    value={editedFile.issue_date}
                    onChange={(e, v) => setEditedFile((o) => ({ ...o, issue_date: v }))}
                    required
                  />
                </Col>
                <Col md={4}>
                  <EditorControls.DateInput
                    label="Valid to"
                    value={editedFile.valid_to}
                    onChange={(e, v) => setEditedFile((o) => ({ ...o, valid_to: v }))}
                    required
                  />
                </Col>
                <Col md={12}>
                  <EditorControls.StringInput
                    label="Issuer"
                    value={editedFile.issuer}
                    onChange={(e, v) => setEditedFile((o) => ({ ...o, issuer: v }))}
                    required
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={onFileSave}
              disabled={!canBeSaved}
            >
              OK
            </Button>
            <Button
              variant="secondary"
              onClick={() => setEditedFile(null)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

Files.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    comment: PropTypes.string,
    folder: PropTypes.number,
  })).isRequired,
  folders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.number,
  onEditFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  dragAllowed: PropTypes.bool,
};

Files.defaultProps = {
  selected: null,
  dragAllowed: true,
};

export default Files;
