import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function DateShow({ value, className }) {
  const v = useMemo(
    () => {
      const d = new Date(value);
      return d.toLocaleString('uk-UA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    [value],
  );
  return <span className={className}>{v}</span>;
}

DateShow.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
};

DateShow.defaultProps = {
  className: '',
  value: null,
};

export default DateShow;
