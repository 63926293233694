import React, {
  useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faFile, faFileZipper, faFolder, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { EditorControls } from '../../../BasicEditor/editorControls';

function Folders({
  folders, onSelect, selected, onAddFolder, onEditFolder, onDeleteFolder, draggedOverFolder, dragAllowed, onDownloadFolder,
}) {
  const [deleteModalOpened, setDeleteModalOpened] = useState(null);
  const [editedFolder, setEditedFolder] = useState(null);
  const currentFolder = useMemo(
    () => folders.filter((f) => f.id === selected).reduce((R, f) => f, {}),
    [folders, selected],
  );
  const folderValues = useMemo(
    () => folders.map((f) => ({ value: f.id, display_name: f.name })),
    [folders],
  );
  return (
    <div className="d-flex flex-column h-100 overflow-hidden">
      <Nav className="gap-2">
        <Button
          size="sm"
          variant="outline-success"
          title="Add folder"
          onClick={() => {
            setEditedFolder({
              folderId: null,
              folderName: 'New folder',
              folderParent: null,
            });
          }}
        >
          <FontAwesomeIcon icon={faFile} />
        </Button>
        <Button
          size="sm"
          variant="outline-primary"
          title="Edit folder"
          onClick={() => {
            setEditedFolder({
              folderId: selected,
              folderName: currentFolder.name,
              folderParent: currentFolder.parent,
            });
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button
          size="sm"
          variant="outline-danger"
          onClick={() => setDeleteModalOpened(true)}
          title="Delete folder"
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        {onDownloadFolder && (
        <Button
          size="sm"
          variant="outline-info"
          onClick={onDownloadFolder}
          title="Download folder"
        >
          <FontAwesomeIcon icon={faFileZipper} />
        </Button>
        )}

      </Nav>
      <Droppable droppableId="Folders">
        {(provided) => (
          <div
            className="flex-fill py-1 px-2 bg-white overflow-auto"
            {...provided.droppabeProps}
            ref={provided.innerRef}
          >
            <Draggable draggableId="ROOT" index={0}>
              {(dragProvided) => {
                const dragCurrent = dragAllowed && draggedOverFolder === null;
                return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                  <div
                    role="listitem"
                    className={classNames('px-2 py-1 rounded ', 'ps-1', { 'bg-secondary-subtle': !selected })}
                    onClick={(e) => onSelect(e, null)}
                    ref={dragProvided.innerRef}
                    {...dragProvided.draggableProps}
                    {...dragProvided.dragHandleProps}
                    style={{ transform: 'none' }}
                  >
                    <FontAwesomeIcon icon={faFolder} className="me-2" bounce={dragCurrent} />
                    ROOT
                  </div>
                );
              }}
            </Draggable>
            {folders.map((f, index) => (
              <Draggable key={f.id} draggableId={`folder-${f.id}`} index={index}>
                {(dragprovided) => {
                  const dragCurrent = dragAllowed && draggedOverFolder === f.id;
                  return (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                    <div
                      role="listitem"
                      key={f.id}
                      className={classNames('px-2 py-1 rounded ', `ps-${f.level + 2}`, { 'bg-secondary-subtle': f.id === selected })}
                      onClick={(e) => onSelect(e, f.id)}
                      ref={dragprovided.innerRef}
                      {...dragprovided.draggableProps}
                      {...dragprovided.dragHandleProps}
                      style={{ transform: 'none' }}
                    >
                      <FontAwesomeIcon icon={faFolder} className="me-2" bounce={dragCurrent} />
                      {f.name}
                    </div>
                  );
                }}
              </Draggable>

            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Modal show={deleteModalOpened} onHide={() => setDeleteModalOpened(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Deleting &quot;
            {currentFolder.name}
            &quot;
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Are you sure to delete folder ${currentFolder.name}?`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={(e) => {
              onDeleteFolder(e, selected);
              setDeleteModalOpened(false);
            }}
          >
            Yes, sure
          </Button>
          <Button variant="secondary" onClick={() => setDeleteModalOpened(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      {editedFolder && (
      <Modal show={!!editedFolder} onHide={() => setEditedFolder(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{editedFolder.folderName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditorControls.StringInput
            label="Name"
            value={editedFolder.folderName}
            onChange={(e, v) => setEditedFolder((o) => ({ ...o, folderName: v }))}
          />
          <EditorControls.SelectorInput
            label="Parent"
            value={editedFolder.folderParent}
            onChange={(e, v) => setEditedFolder((o) => ({ ...o, folderParent: v }))}
            values={folderValues}
            required={false}
            nullLabel="root"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={(e) => {
              if (editedFolder.folderId) {
                onEditFolder(e, {
                  id: editedFolder.folderId,
                  name: editedFolder.folderName,
                  parent: editedFolder.folderParent,
                });
              } else {
                onAddFolder(e, {
                  name: editedFolder.folderName,
                  parent: editedFolder.folderParent,
                });
              }
              setEditedFolder(null);
            }}
          >
            OK
          </Button>
          <Button
            variant="secondary"
            onClick={() => setEditedFolder(null)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      )}
    </div>
  );
}
Folders.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    parent: PropTypes.number,
    level: PropTypes.number,
  })).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.number,
  onAddFolder: PropTypes.func.isRequired,
  onEditFolder: PropTypes.func.isRequired,
  onDeleteFolder: PropTypes.func.isRequired,
  draggedOverFolder: PropTypes.number,
  dragAllowed: PropTypes.bool,
  onDownloadFolder: PropTypes.func,
};

Folders.defaultProps = {
  selected: null,
  draggedOverFolder: null,
  dragAllowed: false,
  onDownloadFolder: null,
};

export default Folders;
