import Checklists from './checklists';
import Interviews from './interviews';
import Documents from './documents';

const reports = {
  Checklists,
  Interviews,
  Documents,
};

export default reports;
