import { faPassport } from '@fortawesome/free-solid-svg-icons';
import DocumentsReport from './documentsReport';

const ReportInfo = {
  component: DocumentsReport,
  url: 'documentsReport',
  icon: faPassport,
  tittle: 'Documents',
  description: 'The report shows a list of documents and their validity periods',
};

export default ReportInfo;
