import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import AppContext from '../../../../providers/authProvider';
import api from '../../../../api/req';
import { backendURI as docTypeURI } from '../../../Catalogs/DocumentTypes/list/uri';
import { backendURI as docCatURI } from '../../../Catalogs/DocumentCategories/list/uri';
import { useDict } from '../../../BaseLister/hook';

const usePeopleDocuments = ({ peopleId }) => {
  const { auth } = useContext(AppContext);
  const [list, setList] = useState([]);
  const [data, setData] = useState({});
  const [err, setErr] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadList = useCallback(
    async () => {
      const r = await api.get('/api/documents/', auth, { people: peopleId });
      if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
      return r.json();
    },
    [auth, peopleId],
  );

  const deleteFile = useCallback(
    (fileId) => {
      const loader = async () => {
        const r = await api.delete(`/api/documents/${fileId}`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return true;
      };
      setLoading(true);
      setErr(null);
      loader()
        .then(() => loadList())
        .then((l) => setList(l))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [auth, loadList],
  );

  const reload = useCallback(
    () => {
      setLoading(true);
      loadList()
        .then((l) => setList(l))
        .catch((e) => setErr(e.message))
        .finally(() => setLoading(false));
    },
    [loadList],
  );

  useEffect(() => reload(), [reload]);

  const docTypes = useDict({ url: docTypeURI });
  const docCats = useDict({ url: docCatURI });

  const categorizedList = useMemo(
    () => {
      const dti = list.map((f) => f.document_type);
      const dt = docTypes.data.filter((d) => dti.includes(d.id));
      const p1 = docCats.data.filter((c) => dt.map((d) => d.category).includes(c.id)).map((c) => ({
        ...c,
        files: dt
          .filter((d) => d.category === c.id)
          .reduce((R, d) => [
            ...R,
            ...list.filter((f) => f.document_type === d.id).map((f) => ({ ...f, document_type: d })),
          ], []),
      }));
      return p1.map((p) => ({
        ...p,
        warnings: p.files.filter((f) => f.warning).length,
        dangers: p.files.filter((f) => f.danger).length,
        expireds: p.files.filter((f) => f.expired).length,
      }));
    },
    [docCats.data, docTypes.data, list],
  );

  return {
    list,
    data,
    setData,
    err,
    loading,
    categorizedList,
    deleteFile,
    reload,
  };
};

export default usePeopleDocuments;
