import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Container, Nav, Row,
} from 'react-bootstrap';
import { SaveButton, DeleteButton, CloseButton } from '../../../../components/Styled/Buttons';
import { backendURI } from '../list/uri';
import useEditor from '../../../BasicEditor/hook/editor';
import EditorContainer from '../../../BasicEditor/editorContainer';
import { EditorControls, editorHooks } from '../../../BasicEditor/editorControls';
import { useDict } from '../../../BaseLister/hook';
import { backendURI as CategoryURI } from '../../DocumentCategories/list/uri';

function CITypeEditor({
  id, onSave, onClose,
}) {
  const {
    isNew, data, systemErrors,
    fields, nonFieldErrors, fieldErrors, actions, loading, options,
  } = useEditor({
    id,
    backendURL: backendURI,
    onSaveCallBack: onSave,
    onCloseCallBack: onClose,
  });
  const nameProps = editorHooks.useStringInputProps('name', data, fields, fieldErrors, actions.onChange);
  const categoryProps = editorHooks.useSelectorInputProps('category', data, fields, fieldErrors, actions.onChange);
  const warnProps = editorHooks.useNumberInputProps('days_to_warning', data, fields, fieldErrors, actions.onChange);
  const dangerProps = editorHooks.useNumberInputProps('days_to_danger', data, fields, fieldErrors, actions.onChange);

  const categories = useDict({ url: CategoryURI });
  return (
    <Container fluid>
      <EditorContainer
        title={data.name}
        isLoading={loading}
        fields={fields}
        err={systemErrors}
        nonFieldErrors={nonFieldErrors}
        fieldErrors={fieldErrors}
        onClearErrors={actions.onClearErrs}
        onClearNonFieldErrors={actions.onClearNonFieldErrors}
      >
        {options.description && (
        <p className="text-muted small">
          {options.description}
        </p>
        )}
        <Nav className="gap-2">
          <SaveButton onClick={() => actions.onSave()} content="Save" />
          <DeleteButton onClick={() => actions.onDelete()} disabled={isNew} />
          <CloseButton onClick={() => onClose()} />
        </Nav>
        <Row>
          <Col md={6}>
            <EditorControls.StringInput {...nameProps} />
          </Col>
          <Col md={6}>
            <EditorControls.SelectorInput {...categoryProps} values={categories.selectorValues} />
          </Col>
          <Col md={6}>
            <EditorControls.NumberInput {...warnProps} />
          </Col>
          <Col md={6}>
            <EditorControls.NumberInput {...dangerProps} />
          </Col>
        </Row>
      </EditorContainer>
    </Container>
  );
}

CITypeEditor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

CITypeEditor.defaultProps = {
  onSave: null,
  onClose: null,
};

export default CITypeEditor;
