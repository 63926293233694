/**
 * Возвращает функцию, которая не будет срабатывать, пока продолжает вызываться.
 * Она сработает только один раз через N миллисекунд после последнего вызова.
 * Если ей передан аргумент `immediate`, то она будет вызвана один раз сразу после
 * первого запуска.
 * @param {function} fn - Функция которая выполнится через указанное время
 * @param {number} delay - указанное время
 * @return {function}

 */

export const debounce = (fn, delay = 250) => {
  let timer = null;
  return (...args) => {
    // const context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

/**
 * Преобразщовывает дату в строку без учета timezone
 * @param {date} date - Дата
 * @return {string}

 */
export const dateToString = (date) => {
  const Year = `${'0000'.slice(0, 4 - date.getFullYear().toString().length)}${date.getFullYear()}`;
  return `${Year}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const getDisplayName = (WrappedComponent) => (
  WrappedComponent.displayName || WrappedComponent.name || 'Component'
);

export const insertIntoArray = (array, position, item) => ([
  ...array.slice(0, position),
  item,
  ...array.slice(position),
]);

export const deleteFromArray = (array, position) => ([
  ...array.slice(0, position),
  ...array.slice(position + 1),
]);

export const getFileTypeFromURL = (URL) => {
  if (URL) {
    const sts = URL.split('.');
    if (sts.length) return sts[sts.length - 1];
  }
  return '';
};

export function readFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });
}
