import React, {
  useCallback, useEffect, useId, useMemo, useState,
} from 'react';
import {
  Col, Row, Form, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import useEditor from '../../../BasicEditor/hook/editor';
import { EditorControls, editorHooks } from '../../../BasicEditor/editorControls';
import EditorContainer from '../../../BasicEditor/editorContainer';
import { useDict } from '../../../BaseLister/hook';
import { readFileAsDataURL } from '../../../../api/utils';
import { SaveButton } from '../../../../components/Styled/Buttons';

function FileEditor({ id, onSave, peopleId }) {
  const {
    data, fields, fieldErrors, actions: { onChange, onSave: save }, loading,
    systemErrors, nonFieldErrors, onClearErrs, onClearNonFieldErrors,
  } = useEditor({ backendURL: '/api/documents/', id, onSaveCallBack: onSave });

  useEffect(() => {
    if (id === 'create' && !data.people) onChange({ people: peopleId });
  }, [data.people, id, onChange, peopleId]);

  const documentTypeProps = editorHooks.useSelectorInputProps('document_type', data, fields, fieldErrors, onChange);
  const documentTypes = useDict({ url: '/api/cat/document_type/' });
  const isseDateProps = editorHooks.useDateInputProps('issue_date', data, fields, fieldErrors, onChange);
  const issuerProps = editorHooks.useStringInputProps('issuer', data, fields, fieldErrors, onChange);
  const validToProps = editorHooks.useDateInputProps('valid_to', data, fields, fieldErrors, onChange);
  const commentProps = editorHooks.useStringInputProps('comment', data, fields, fieldErrors, onChange);
  const fileId = useId();

  const fileURL = useMemo(() => {
    if (!data.file) return null;
    if (typeof data.file === 'string') return data.file;
    return data.file.file;
  }, [data.file]);

  const onChangeFile = useCallback((e) => {
    const f = e.target.files[0];
    readFileAsDataURL(f).then((file) => onChange({
      file: { file, filename: f.name },
    }));
  }, [onChange]);
  const [noDate, setNoDate] = useState(false);
  useEffect(() => {
    if (data.id && !data.valid_to && !noDate) setNoDate(true);
  }, [data.id, data.valid_to, noDate]);

  return (
    <EditorContainer
      isLoading={loading}
      fields={fields}
      err={systemErrors}
      nonFieldErrors={nonFieldErrors}
      fieldErrors={fieldErrors}
      onClearErrors={onClearErrs}
      onClearNonFieldErrors={onClearNonFieldErrors}
    >
      <Row>
        <Col>
          <EditorControls.SelectorInput {...documentTypeProps} values={documentTypes.selectorValues} />
        </Col>
        <Col>
          <EditorControls.StringInput {...issuerProps} />
        </Col>
        <Col>
          <EditorControls.DateInput {...isseDateProps} />
        </Col>
        <Col>
          <EditorControls.DateInput {...validToProps} disabled={noDate} />
          <EditorControls.CheckboxInput
            value={noDate}
            onChange={(e, v) => {
              setNoDate(v);
              if (v) onChange({ valid_to: null });
            }}
            label="Has no expiration date"
          />
        </Col>
        <Col sm={12} className="my-2">
          <Form.Group controlId={fileId}>
            <Form.Label className="btn btn-primary">{fields?.file?.label}</Form.Label>
            <Form.Control type="file" className="d-none" onChange={onChangeFile} />
          </Form.Group>
          <Button variant="link" size="sm" disabled={!fileURL} href={fileURL} target="_blank">
            Preview file
          </Button>
        </Col>
        <Col sm={12}>
          <EditorControls.TextInput rows={3} {...commentProps} />
        </Col>
        <hr />
        <SaveButton onClick={save} disabled={!data.valid_to && !noDate} />
      </Row>
    </EditorContainer>

  );
}

FileEditor.propTypes = {
  id: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default FileEditor;
