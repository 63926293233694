import React, {
  memo, useContext, useEffect, useMemo, useState,
} from 'react';
import { Alert, Container, Table } from 'react-bootstrap';
import { useDict } from '../../BaseLister/hook';
import { EditorControls } from '../../BasicEditor/editorControls';
import { Loader } from '../../../components/Styled/Misc';
import AppContext from '../../../providers/authProvider';
import api from '../../../api/req';
import DocumentReportRow from './row';

function DocumentsReport() {
  const [selecttedType, setSelectedType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [data, setData] = useState([]);
  const { auth } = useContext(AppContext);
  const dctDict = useDict({ url: '/api/cat/document_type/' });
  const dctvalues = useMemo(
    () => {
      const cats = dctDict.data.reduce((R, v) => ({ ...R, [v.category.id]: v.category }), {});
      return Object.values(cats)
        .sort((a, b) => {
          if (a.repr > b.repr) return 1;
          if (a.repr < b.repr) return -1;
          return 1;
        })
        .reduce((R, cat) => [
          ...R,
          { value: -cat.id, display_name: cat.repr, disabled: true },
          ...dctDict.data.filter((dc) => dc.category.id === cat.id).map((dc) => ({
            value: dc.id,
            display_name: dc.repr,
          })),
        ], []);
    },
    [dctDict.data],
  );

  useEffect(
    () => {
      const loader = async () => {
        const r = await api.get(`/api/reports/documents/${selecttedType}/`, auth);
        if (!r.ok) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      };
      if (selecttedType !== null) {
        setLoading(true);
        setErr(null);
        loader()
          .then((d) => setData(d))
          .catch((e) => setErr(e.message))
          .finally(() => setLoading(false));
      }
    },
    [auth, selecttedType],
  );

  return (
    <Container fluid>
      <h1>Documents report</h1>
      {loading && <Loader />}
      {err && <Alert variant="danger">{err}</Alert>}
      <EditorControls.SelectorInput
        label="File type"
        value={selecttedType}
        onChange={(e, v) => setSelectedType(v)}
        values={dctvalues}
        required
        searchable={false}
      />
      <Table bordered hover>
        <thead>
          <tr>
            <th>Canidate</th>
            <th>Date of Issue</th>
            <th>Issuer</th>
            <th>Valid to</th>
            <th>Download document</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <DocumentReportRow row={row} key={row.id} />
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default memo(DocumentsReport);
